<template>
    <div class="col-12 mb-1">
        <div
            data-bs-toggle="collapse"
            class="collapsed"
            :data-bs-target="`#history-${ramId}`"
            :aria-expanded="true"
            :aria-controls="`history-${ramId}`"
        >
            {{ DateTime.fromISO(history.date).toFormat('yyyy-MM-dd h:mm a') }} -
            {{ history.user?.fullName }}
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="collapse" :id="`history-${ramId}`">
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Tipo</td>
                            <td>: {{ history.data.type }}</td>
                        </tr>
                        <tr>
                            <td>Fecha Inicio</td>
                            <td>
                                :
                                {{
                                    DateTime.fromMillis(history.data.timeStart).toFormat(
                                        'yyyy-MM-dd h:mm a'
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Fecha Final</td>
                            <td>
                                :
                                {{
                                    DateTime.fromMillis(history.data.timeEnd).toFormat(
                                        'yyyy-MM-dd h:mm a'
                                    )
                                }}
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>Paciente</td>
                            <td>: {{ history.data.paciente }}</td>
                        </tr> -->
                        <tr>
                            <td>Estado</td>
                            <td>: {{ status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'CitaModHistoryRow',
    props: {
        history: Object,
    },
    setup(props) {
        const ramId = ref(Math.floor(Math.random() * 1000));
        const status = computed(() => {
            switch (props.history.data.status) {
                case 1:
                case '1':
                    return 'Programada';
                case 2:
                case '2':
                    return 'En sala de espera';
                case 3:
                case '3':
                    return 'Finalizada';
                case 0:
                case '0':
                    return 'Cancelada';
                default:
                    return '';
            }
        });
        return { ramId, status, DateTime };
    },
};
</script>
<style lang="scss" scoped></style>
